/**
 * NOTE: THESE ARE GLOBAL STYLES!
 * Anything put here will apply to both this (host) app and all remote apps.
 * Use this file sparingly—if at all!
 */

body {
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
  font-family: Lato, sans-serif;
  font-size: 14px;
}

img {
  max-width: 100%;
}

/**
 * Disables the nasty warning about @reach/dialog styles.
 * Note: we don't need them!
 * @see: https://github.com/reach/reach-ui/issues/136
 */
:root {
  --reach-dialog: 1;
}
